#footer {
  text-align: center;
  margin: 50px 0 20px 0;
}

.links {
  padding-bottom: 15px;
}

#to-review a {
  text-decoration: underline;
}

table {
  & .snug {
    width: 1px;
    white-space: nowrap;
    text-align: center;

    &.wrap {
      white-space: pre;
    }
  }

  &.table tbody tr td {
    vertical-align: middle;
  }
}
