#approvals {
  margin-top: 20px;
}

.radio-btns.has-error input[type=radio]:not(:checked) + label {
  color: #a94442 !important;
  border-color: #a94442 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

#request-actions .row {
  padding-bottom: 15px;
}
#request-actions .row:last-child {
  padding-bottom: 0px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3JhaWxzL2FwcC92aWV3cy9hZG1pbi9yZXF1ZXN0cy9jc3MiLCJzb3VyY2VzIjpbImluZGV4LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7O0FBRUE7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIiNhcHByb3ZhbHMge1xuICBtYXJnaW4tdG9wOiAyMHB4O1xufVxuXG4ucmFkaW8tYnRucy5oYXMtZXJyb3IgaW5wdXRbdHlwZT1cInJhZGlvXCJdOm5vdCg6Y2hlY2tlZCkgKyBsYWJlbCB7XG4gIGNvbG9yOiAjYTk0NDQyICFpbXBvcnRhbnQ7XG4gIGJvcmRlci1jb2xvcjogI2E5NDQ0MiAhaW1wb3J0YW50O1xuICBib3gtc2hhZG93OiBpbnNldCAwIDFweCAxcHggcmdiYSgwLCAwLCAwLCAuMDc1KTtcbn1cblxuI3JlcXVlc3QtYWN0aW9ucyAucm93IHtcbiAgcGFkZGluZy1ib3R0b206IDE1cHg7XG5cbiAgJjpsYXN0LWNoaWxkIHtcbiAgICBwYWRkaW5nLWJvdHRvbTogMHB4O1xuICB9XG59XG4iXX0= */