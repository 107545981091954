@use "~bootstrap-sass/assets/stylesheets/bootstrap/mixins/clearfix";

@mixin half-day($top, $bottom) {
  background: rgba(0, 0, 0, 0) linear-gradient(135deg, $top, $top 49%, $bottom 51%, $bottom) repeat scroll 0% 0%;
}

@mixin day-animations($name, $color) {
  @keyframes #{$name} {
    0% { background-color: $color; }
    50% { background-color: lighten($color, 40%); }
    100% { background-color: $color; }
  }

  @keyframes #{$name}-half-start {
    0% { @include half-day(transparent, $color); }
    50% { @include half-day(transparent, lighten($color, 40%)); }
    100% { @include half-day(transparent, $color); }
  }

  @keyframes #{$name}-half-end {
    0% { @include half-day($color, transparent); }
    50% { @include half-day(lighten($color, 40%), transparent); }
    100% { @include half-day($color, transparent); }
  }
}

@mixin day-styles($name, $color) {
  & .#{$name} {
    background-color: $color;
    &.new, &.current {
      animation: #{$name}-new 2s infinite;
      animation-timing-function: ease-in-out;
    }

    &.half-day-start {
      @include half-day(transparent, $color);
      &.new, &.current {
        animation: #{$name}-new-half-start 2s infinite;
      }
    }

    &.half-day-end {
      @include half-day($color, transparent);
      &.new, &.current {
        animation: #{$name}-new-half-end 2s infinite;
      }
    }
  }
}

$paid-bg: #337ab7;
$unpaid-bg: #d9534f;
$holiday-bg: #c0c0c0;
$break-bg: #ddd;

@include day-animations(paid-new, $paid-bg);
@include day-animations(unpaid-new, $unpaid-bg);

.calendar, #legend {
  & .break   { background-color: $break-bg; }
  & .holiday { background-color: $holiday-bg; }

  & .paid, & .unpaid {
    position: relative;

    & svg {
      position: absolute;
      top: -6px;
      right: -6px;
      color: #5cb85c;
      font-size: 16px;
      z-index: 100;
    }
  }

  @include day-styles(paid, $paid-bg);
  @include day-styles(unpaid, $unpaid-bg);
}

.calendar {
  overflow: visible;
  margin-bottom: 20px;

  & .months-container {
    @include clearfix.clearfix;
    height: auto;

    & .month-container {
      height: 220px;
      padding: 10px 0;
    }
  }

  & .today {
    font-weight: bold;
  }

  &.editing {
    border: 2px solid #faebcc;
  }
}

#legend {
  & h4 {
    margin: 0 0 15px 0;
  }

  & p {
    margin-top: 15px;
  }

  & .column {
    display: inline-block;
    width: 24.5%;
  }

  & .entry {
    position: relative;
  }

  & .day {
    display: table-cell;
    text-align: center;
    width: 20px;
    height: 20px;
    border: none;
    padding: 0;
    font-size: 12px;

    & .day-content {
      padding: 5px 6px;
    }
  }

  & .day-label {
    display: table-cell;
    vertical-align: middle;
    padding-left: 10px;

    &.half-label {
      display: block;
      position: absolute;
      top: 18px;
      left: 26px;
    }
  }
}
