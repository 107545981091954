#approvals {
  margin-top: 20px;
}

.radio-btns.has-error input[type="radio"]:not(:checked) + label {
  color: #a94442 !important;
  border-color: #a94442 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

#request-actions .row {
  padding-bottom: 15px;

  &:last-child {
    padding-bottom: 0px;
  }
}
