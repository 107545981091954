#footer {
  text-align: center;
  margin: 50px 0 20px 0;
}

.links {
  padding-bottom: 15px;
}

#to-review a {
  text-decoration: underline;
}

table .snug {
  width: 1px;
  white-space: nowrap;
  text-align: center;
}
table .snug.wrap {
  white-space: pre;
}
table.table tbody tr td {
  vertical-align: middle;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3JhaWxzL2FwcC92aWV3cy9sYXlvdXRzL2NzcyIsInNvdXJjZXMiOlsiaW5kZXguc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFO0VBQ0E7OztBQUdGO0VBQ0U7OztBQUdGO0VBQ0U7OztBQUlBO0VBQ0U7RUFDQTtFQUNBOztBQUVBO0VBQ0U7O0FBSUo7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIiNmb290ZXIge1xuICB0ZXh0LWFsaWduOiBjZW50ZXI7XG4gIG1hcmdpbjogNTBweCAwIDIwcHggMDtcbn1cblxuLmxpbmtzIHtcbiAgcGFkZGluZy1ib3R0b206IDE1cHg7XG59XG5cbiN0by1yZXZpZXcgYSB7XG4gIHRleHQtZGVjb3JhdGlvbjogdW5kZXJsaW5lO1xufVxuXG50YWJsZSB7XG4gICYgLnNudWcge1xuICAgIHdpZHRoOiAxcHg7XG4gICAgd2hpdGUtc3BhY2U6IG5vd3JhcDtcbiAgICB0ZXh0LWFsaWduOiBjZW50ZXI7XG5cbiAgICAmLndyYXAge1xuICAgICAgd2hpdGUtc3BhY2U6IHByZTtcbiAgICB9XG4gIH1cblxuICAmLnRhYmxlIHRib2R5IHRyIHRkIHtcbiAgICB2ZXJ0aWNhbC1hbGlnbjogbWlkZGxlO1xuICB9XG59XG4iXX0= */