#employee-search-results {
  & .list-group-item {
    width: 100%;
    display: table;

    & span {
      display: table-cell;
      vertical-align: middle;
    }
  }
}
