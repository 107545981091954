@use "bootstrap-sass/assets/stylesheets/bootstrap";
@use "calendar.scss";

#days-selected {
  text-align: center;
  margin: 20px 0;

  & .text, & .remaining {
    font-weight: bold;
    font-size: 1.3em;
  }

  & .remaining {
    padding-top: 10px;
  }

  & .text, & #clear-days {
    display: inline-block;
  }

  & #clear-days {
    margin-left: 20px;
  }
}

.request_meta {
  & label, & input {
    display: none;
  }
}

#request-list {
  margin-top: 30px;

  & .request-status {
    & .h5 {
      display: inline-block;
      margin: 0 5px 10px 0;
    }

    & span.label {
      vertical-align: top;
    }
  }
}

#approve-deny {
  & .button_to {
    display: inline-block;
  }
}

#denial-reason {
  padding-top: 25px;
}

.radio-btns {
  & input[type="radio"] {
    display: none;

    & + label {
      @extend .btn;
    }

    &:not(:checked) + label {
      @extend .btn-default;

      & .fa-circle-dot {
        display: none;
      }
    }

    &:checked + label {
      &.good {
        @extend .btn-success;
      }

      &.bad {
        @extend .btn-danger;
      }

      & .fa-circle {
        display: none;
      }
    }
  }
}

fieldset[disabled] {
  & .radio-btns label {
    @extend .btn, .disabled;
  }

  & label.control-label {
    @extend .text-muted;
  }
}
